import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={privacyPageStyle}>
      <h1>Privacy Policy</h1>
      <p>Effective Date: [Insert Date]</p>
      <p>At AH Wings, we value your privacy and are committed to protecting your personal information. This privacy policy explains how we collect, use, and share your data when you visit our website.</p>

      <h3>1. Information We Collect</h3>
      <p>We may collect the following information when you use our site:
        <ul>
          <li>Personal information (name, email address, etc.)</li>
          <li>Non-personal information (IP address, browser type, etc.)</li>
        </ul>
      </p>

      <h3>2. How We Use Your Information</h3>
      <p>Your information is used to improve the functionality and experience of our site, including:
        <ul>
          <li>Personalizing content</li>
          <li>Sending newsletters and updates (if subscribed)</li>
          <li>Providing customer support</li>
        </ul>
      </p>

      <h3>3. Data Security</h3>
      <p>We implement appropriate security measures to protect your personal information. However, no method of transmission over the internet is 100% secure.</p>

      <h3>4. Cookies</h3>
      <p>We use cookies to enhance your browsing experience. By using our site, you consent to our use of cookies. You can control cookie preferences through your browser settings.</p>

      <h3>5. Third-Party Services</h3>
      <p>We may use third-party services such as Google AdSense, which may collect and use data for advertising purposes. Please refer to their privacy policies for more information.</p>

      <h3>6. Changes to This Privacy Policy</h3>
      <p>We may update our Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date.</p>

      <h3>7. Contact Us</h3>
      <p>If you have any questions about this Privacy Policy, please contact us at: [insert contact details].</p>
    </div>
  );
};

// Styles for Privacy Policy page
const privacyPageStyle = {
  padding: '20px',
  maxWidth: '800px',
  margin: '0 auto',
  marginTop: '70px'
};

export default PrivacyPolicy;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import CInterview from './Pages/Cinterview';
import CPlusPlusInterview from './Pages/CPlusPlusInterview';
import PythonInterview from './Pages/PythonInterview';
import JavaInterview from './Pages/JavaInterview';
import HtmlInterview from './Pages/HtmlInterview';
import CssInterview from './Pages/CssInterview';
import SqlInterview from './Pages/SqlInterview';
import JobPortal from './Pages/jobportal';
import TermsOfService from './Pages/terms';
import PrivacyPolicy from './Pages/privacy';
import About from './Pages/about';

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
      <Route path="/" element={<Home />} />
        <Route path="/c-interview" element={<CInterview />} />
        <Route path="/cpp-interview" element={<CPlusPlusInterview />} />
        <Route path="/java-interview" element={<JavaInterview />} />
        <Route path="/python-interview" element={<PythonInterview />} />
        <Route path="/html-interview" element={<HtmlInterview />} />
        <Route path="/css-interview" element={<CssInterview />} />
        <Route path="/sql-interview" element={<SqlInterview />} />
        <Route path="/job-portal" element={<JobPortal />} />
        <Route path="/about" element={<About />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </Router>
  );
};

const appStyle = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
};

const contentStyle = {
  flex: '1', // This makes sure the content takes up the remaining space, pushing the footer to the bottom
};

export default App;

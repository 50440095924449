import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import AdSenseAd from '../components/Adsense'; // Import AdSenseAd component
import '../App.css';
import Papa from 'papaparse'; // For robust CSV parsing

const CSSQaPortal = () => {
  const [qaData, setQaData] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vQFPR-Ug4448ebL2q_hTJ2uQU_8F_bcBW81jqrLN929FtP8hyzVH_ldbIvicUTrq1_tG5j__AD8pQQO/pub?gid=124460662&single=true&output=csv')
        .then(response => response.text())
        .then(csvText => {
          Papa.parse(csvText, {
            header: true, // Parse headers for cleaner mapping
            skipEmptyLines: true, // Skip empty rows
            complete: (result) => {
              setQaData(result.data); // Set parsed data
            },
          });
        })
        .catch(err => console.error('Error fetching data:', err));
    };

    fetchData(); // Fetch data initially
    const interval = setInterval(fetchData, 60000); // Polling every minute

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  return (
    <div style={{ backgroundColor: '', padding: '20px', minHeight: '100vh',marginTop: '70px' }}>
      {/* SEO Meta Tags */}
      <meta
        name="keywords"
        content="CSS interview questions, CSS job preparation, technical interview CSS, freshers CSS interview, experienced CSS interview, CSS tips, CSS coding questions, advanced CSS questions, CSS Flexbox, CSS Grid, CSS animations, Responsive CSS, CSS media queries, CSS selectors, CSS pseudo-classes, CSS pseudo-elements, CSS inheritance, CSS specificity, CSS performance optimization, CSS tools, CSS frameworks, CSS preprocessors, SASS, LESS, Tailwind CSS, Bootstrap, CSS debugging tips, modern CSS techniques"
      />
      <div className="container mt-4">
        <a
          className="text-black font-weight-bold mb-3"
          style={{
            textShadow: '1px 1px 2px white, 0 0 25px white, 0 0 5px white',
            fontSize: '30px',
            fontWeight: 'bold',
            textAlign: 'center',
            display: 'block',
            width: '100%',
            margin: '0 auto',
          }}
        >
          CSS INTERVIEW QUESTIONS
        </a>

        {/* AdSense Advertisement */}
        <AdSenseAd />

        <div className="qa-listings" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          {qaData.map((qa, index) => (
            <Card
              style={{
                backgroundColor: '#935CEB',
                height: 'auto',
                width: '100%',
                maxWidth: '600px',
                margin: '15px 0',
                padding: '25px',
                borderRadius: '8px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              }}
              key={index}
            >
              <Card.Body>
                <Card.Title style={{ color: '#ffffff', fontSize: '18px' }}>
                  <strong>Question:</strong>
                  <br />
                  {qa.Question.split('\n').map((line, idx) => (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  ))}
                </Card.Title>
                <Card.Text style={{ color: '#ffffff', fontSize: '16px' }}>
                  <strong>Answer:</strong>
                  <br />
                  {qa.Answer.split('\n').map((line, idx) => (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  ))}
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CSSQaPortal;

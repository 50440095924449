import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import Papa from 'papaparse'; // Import Papa Parse for robust CSV parsing
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../App.css';

const SqlQaPortal = () => {
  const [qaData, setQaData] = useState([]); // State to hold question-answer data

  useEffect(() => {
    const fetchData = () => {
      fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vQFPR-Ug4448ebL2q_hTJ2uQU_8F_bcBW81jqrLN929FtP8hyzVH_ldbIvicUTrq1_tG5j__AD8pQQO/pub?gid=1410050604&single=true&output=csv')
        .then(response => response.text())
        .then(csvText => {
          Papa.parse(csvText, {
            header: true, // Treat the first row as column headers
            skipEmptyLines: true, // Skip empty rows
            complete: (result) => {
              setQaData(result.data); // Set parsed data into state
            },
          });
        })
        .catch(err => console.error('Error fetching data:', err));
    };

    fetchData(); // Fetch data initially
    const interval = setInterval(fetchData, 60000); // Polling for live updates every minute

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return (
    <div style={{ backgroundColor: '', padding: '20px', minHeight: '100vh',marginTop: '70px' }}>
      {/* SEO Meta Tags */}
      <meta
        name="description"
        content="Explore SQL interview questions and answers. Perfect for job preparation, covering topics like database management, queries, SQL optimization, and more."
      />
     <meta
        name="keywords"
        content="SQL interview questions, SQL coding questions, SQL job interview preparation, SQL tutorials, SQL for beginners, SQL database management, SQL queries, SQL optimization, advanced SQL queries, SQL performance tuning, SQL interview tips, SQL problem-solving, SQL interview practice, SQL data types, SQL subqueries, SQL joins, SQL indexing, SQL triggers, SQL functions, SQL procedures, SQL aggregation, SQL query writing, SQL database design, SQL normalization, SQL data manipulation, SQL DDL, SQL DML, SQL data retrieval, SQL interview questions for experienced, SQL certification preparation, SQL for developers, SQL for data analysts, SQL for software engineers, SQL for data scientists, SQL questions for freshers" 
      />

      <meta name="author" content="Your Name or Company" />

      <Navbar />
      <div className="container mt-4">
        <a
          className="text-black font-weight-bold mb-3"
          style={{
            textShadow: '1px 1px 2px white, 0 0 25px white, 0 0 5px white',
            fontSize: '30px',
            fontWeight: 'bold',
            textAlign: 'center',
            display: 'block',
            width: '100%',
            margin: '0 auto',
          }}
        >
          SQL INTERVIEW QUESTIONS
        </a>

        <div className="qa-listings" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          {qaData.map((qa, index) => (
            <Card
              style={{
                backgroundColor: '#935CEB',
                height: 'auto',
                width: '100%',
                maxWidth: '600px',
                margin: '15px 0',
                padding: '25px',
                borderRadius: '8px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              }}
              key={index}
            >
              <Card.Body>
                <Card.Title style={{ color: '#ffffff', fontSize: '18px' }}>
                  <strong>Question:</strong>
                  <br />
                  {qa.Question.split('\n').map((line, idx) => (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  ))}
                </Card.Title>
                <Card.Text style={{ color: '#ffffff', fontSize: '16px' }}>
                  <strong>Answer:</strong>
                  <br />
                  {qa.Answer.split('\n').map((line, idx) => (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  ))}
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SqlQaPortal;

import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import react-helmet for SEO
import AdSenseAd from "../components/Adsense";

const Home = () => {
  const [qaData, setQAData] = useState([]); // State to hold job data
  const scrollingRef = useRef(null); // Create a reference for the scroll container

  useEffect(() => {
    // Fetch job data from the API (Google Sheets link can be used here)
    fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vQFPR-Ug4448ebL2q_hTJ2uQU_8F_bcBW81jqrLN929FtP8hyzVH_ldbIvicUTrq1_tG5j__AD8pQQO/pub?gid=106539113&single=true&output=csv')
      .then(response => response.text())
      .then(text => {
        const rows = text
          .split('\n')  // Split CSV rows
          .slice(1)     // Skip the header row
          .map(row => row.split(',')) // Split each row by commas
          .map(row => ({
            jobName: row[0] || 'No job name',
            companyName: row[1] || 'No company name',
            applyLink: row[2] || '#',
          }));
        setQAData(rows.reverse()); // Reverse to show latest job first
      })
      .catch(err => console.error('Error fetching data:', err));

    // Automatic scrolling interval
    const scrollInterval = setInterval(() => {
      if (scrollingRef.current) {
        // Scroll the container by 1px
        scrollingRef.current.scrollLeft += 1;

        // Reset scroll position to 0 once it reaches the end to create infinite scroll effect
        if (scrollingRef.current.scrollLeft >= scrollingRef.current.scrollWidth / 2) {
          scrollingRef.current.scrollLeft = 0;
        }
      }
    }, 10); // Adjust the speed by changing this value

    // Clear the interval when component unmounts
    return () => clearInterval(scrollInterval);
  }, []);

  return (
    <div style={{ textAlign: 'center', padding: '20px',marginTop: '70px' }}>
      {/* SEO Meta Tags for "ny ah wings" */}
      <Helmet>
        <title>ah Wings | ah wings Job Portal</title>
        <meta name="description" content="Discover the latest NY Ah Wings opportunities, job postings, and much more at [Your Website]. Apply now!" />
        <meta name="keywords" content="ah wings, job portal, apply now, job opportunities" />
      </Helmet>

      {/* Horizontal Scrolling for Job Portal */}
      <h2>Job Portal</h2>
      <div 
        ref={scrollingRef} 
        style={{ 
          overflowX: 'hidden',  // Hide the scrollbar
          whiteSpace: 'nowrap', 
          padding: '10px 0', 
          display: 'flex', 
          width: '100%',
        }}
      >
        {/* First instance of the job items */}
        <div style={{ display: 'flex' }}>
          <AdSenseAd />
          {qaData.map((job, index) => (
            <div key={index} style={{ display: 'inline-block', margin: '0 10px', minWidth: '250px' }}>
              <div style={scrollingLinkStyle}>
                <div style={{ fontSize: '28px',color: '#FFFFFF' }}>{job.jobName}</div>
                <div style={{ fontSize: '25px', margin: '5px 0',color: '#FFFFFF' }}>{job.companyName}</div>
                <a 
                  href={job.applyLink} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  style={{ fontSize: '22px', color: '#FFFFFF', textDecoration: 'underline' }}
                >
                  Apply Now
                </a>
              </div>
            </div>
          ))}
        </div>

        {/* Duplicate instance of the job items */}
        <div style={{ display: 'flex' }}>
          {qaData.map((job, index) => (
            <div key={index + qaData.length} style={{ display: 'inline-block', margin: '0 10px', minWidth: '200px' }}>
              <div style={scrollingLinkStyle}>
                <div style={{ fontWeight: 'bold', fontSize: '16px' }}>{job.jobName}</div>
                <div style={{ fontSize: '14px', margin: '5px 0' }}>{job.companyName}</div>
                <a 
                  href={job.applyLink} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  style={{ fontSize: '14px', color: '#58368F', textDecoration: 'underline' }}
                >
                  Apply Now
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Interview Questions Links */}
      <h2>Interview Questions</h2>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px', margin: '20px 0' }}>
        <Link to="/c-interview" style={buttonStyle}>C Interview Questions</Link>
        <Link to="/cpp-interview" style={buttonStyle}>C++ Interview Questions</Link>
        <Link to="/java-interview" style={buttonStyle}>Java Interview Questions</Link>
        <Link to="/python-interview" style={buttonStyle}>Python Interview Questions</Link>
        <Link to="/html-interview" style={buttonStyle}>HTML Interview Questions</Link>
        <Link to="/css-interview" style={buttonStyle}>CSS Interview Questions</Link>
        <Link to="/sql-interview" style={buttonStyle}>SQL Interview Questions</Link>
      </div>
    </div>
  );
};

// Button Styles
const buttonStyle = {
  padding: '20px 40px',
  fontSize: '18px',
  backgroundColor: '#58368F',
  color: 'white',
  textDecoration: 'none',
  borderRadius: '10px',
  transition: 'background-color 0.3s',
  textAlign: 'center',
  width: '200px',
};

// Scroll Link Styles
const scrollingLinkStyle = {
  display: 'flex',
  flexDirection: 'column', // Stack the job name, company, and apply link vertically
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '10px',
  backgroundColor: '#58368F',
  borderRadius: '10px',
  minHeight: '150px',
  marginRight: '15px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
};

export default Home;

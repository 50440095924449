import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import '../App.css';

const JobPortal = () => {
  const [qaData, setQAData] = useState([]); // State to hold QA data
  const [show, setShow] = useState(false);
  const [selectedQA, setSelectedQA] = useState(null); // State for the selected QA item

  useEffect(() => {
    // Fetch job data from Google Sheets (ensure your sheet is set to public access)
    fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vQFPR-Ug4448ebL2q_hTJ2uQU_8F_bcBW81jqrLN929FtP8hyzVH_ldbIvicUTrq1_tG5j__AD8pQQO/pub?gid=106539113&single=true&output=csv')
      .then(response => response.text())
      .then(text => {
        const rows = text
          .split('\n')  // Split CSV rows
          .slice(1)     // Skip the header row
          .map(row => row.split(',')) // Split each row by commas
          .map(row => ({
            jobName: row[0] || 'No job name', // Default to avoid errors
            companyName: row[1] || 'No company name', // Default if missing
            applyLink: row[2] || '#', // Default if missing or empty
          }));
        setQAData(rows.reverse()); // Reverse to show latest job first
      })
      .catch(err => console.error('Error fetching data:', err));
  }, []);

  const handleShow = (qa) => {
    setSelectedQA(qa); // Set the selected QA for the modal
    setShow(true); // Show the modal
  };

  const handleClose = () => setShow(false);

  return (
    <>
      {/* SEO Meta Tags */}
      <meta name="description" content="Explore the latest job listings and career opportunities across top companies. Apply for jobs in tech, finance, healthcare, digital marketing, and more. Freshers and experienced professionals welcome!" />
      <meta name="keywords" content="job portal, job listings, career opportunities, job openings, apply for jobs, job vacancies, job application, jobs in tech, finance jobs, job search, remote job openings, work from home, cybersecurity jobs, AI careers, cloud computing jobs, data science jobs, marketing jobs, healthcare jobs, sustainability careers, green energy jobs, renewable energy jobs, digital marketing, remote work, flexible jobs, life sciences research jobs, content marketing, SEO jobs, sales careers, jobs for freshers, fresher jobs, entry-level jobs, jobs for freshers in tech, freshers hiring, jobs for 2024 graduates, internships, experienced jobs, mid-level jobs, career growth, jobs for experienced professionals" />
      <meta name="author" content="Your Name or Company" />


      <div style={{ backgroundColor: '#ffffff', padding: '20px', minHeight: '100vh',marginTop: '70px' }}>
        <div className="container mt-4">
          <a 
            className="text-black font-weight-bold mb-3" 
            style={{ 
              textShadow: '1px 1px 2px white, 0 0 25px white, 0 0 5px white, 0 0 5px white',
              fontSize: '30px',
              fontWeight: 'bold',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              margin: '0 auto'
            }}
          >
            JOB LISTINGS
          </a>

          <div className="qa-listings" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {qaData.map((job, index) => (
              <div 
                className="card" 
                style={{ 
                  backgroundColor: '#935CEB',
                  height: 'auto',
                  width: '300px',
                  margin: '10px',
                  padding: '20px',
                  cursor: 'pointer',
                  boxSizing: 'border-box',
                  borderRadius: '8px',
                }} 
                key={index}
                onClick={() => handleShow(job)} // Show modal with the selected job
              >
                <div className="card-body">
                  <h5 className="card-title" style={{ color: '#ffffff', marginBottom: '10px', fontSize: '16px' }}>
                    <strong>Job Name:</strong> {job.jobName}
                  </h5>
                  <p className="card-text" style={{ color: '#ffffff', fontSize: '14px' }}>
                    <strong>Company:</strong> {job.companyName}
                  </p>
                  <p className="card-text" style={{ color: '#ffffff', fontSize: '14px' }}>
                    <strong>Apply Link:</strong> <a href={job.applyLink} target="_blank" rel="noopener noreferrer" style={{ color: '#ffcc00' }}>Apply Now</a>
                  </p>
                </div>
              </div>
            ))}
          </div>

        </div>
      </div>
    </>
  );
};

export default JobPortal;

import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap'; // Import Card component
import Footer from '../components/Footer';
import Papa from 'papaparse'; // Import Papa Parse
import '../App.css';
import AdSenseAd from "../components/Adsense";

const CppQAPortal = () => {
  const [qaData, setQaData] = useState([]); // State to hold question-answer data

  useEffect(() => {
    const fetchData = () => {
      fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vQFPR-Ug4448ebL2q_hTJ2uQU_8F_bcBW81jqrLN929FtP8hyzVH_ldbIvicUTrq1_tG5j__AD8pQQO/pub?gid=1444007767&single=true&output=csv')
        .then(response => response.text())
        .then(csvText => {
          Papa.parse(csvText, {
            header: true, // Read the first row as column headers
            skipEmptyLines: true, // Skip any empty rows
            complete: (result) => {
              setQaData(result.data); // Set the parsed data
            },
          });
        })
        .catch(err => console.error('Error fetching data:', err));
    };

    fetchData(); // Fetch data initially
    const interval = setInterval(fetchData, 60000); // Polling for live updates every minute

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return (
    <div style={{ backgroundColor: '', padding: '20px', minHeight: '100vh',marginTop: '70px' }}>
      {/* SEO Meta Tags */}
      <meta
        name="keywords"
        content="C++ interview questions, C++ programming interview, technical interview C++, C++ language interview questions, C++ coding questions, C++ job preparation, freshers C++ interview, experienced C++ interview, C++ programming tips, C++ basic questions, C++ advanced questions, C++ memory management, C++ dynamic memory allocation, Pointers in C++, C++ arrays, C++ strings, C++ STL, Standard Template Library C++, C++ data structures, Linked lists in C++, Polymorphism in C++, Inheritance in C++, Virtual functions in C++, C++ constructors and destructors, Operator overloading in C++, Exception handling in C++, C++ templates, C++ file handling, C++ debugging tips, C++ algorithm questions, C++ competitive programming, Real-world C++ projects, Embedded systems in C++, C++ system design"
      />
      <div className="container mt-4">
        <a
          className="text-black font-weight-bold mb-3"
          style={{
            textShadow: '1px 1px 2px white, 0 0 25px white, 0 0 5px white',
            fontSize: '30px',
            fontWeight: 'bold',
            textAlign: 'center',
            display: 'block',
            width: '100%',
            margin: '0 auto',
          }}
        >
          C++ INTERVIEW QUESTIONS
        </a>

        <div className="qa-listings" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          {qaData.map((qa, index) => (
            <Card
              style={{
                backgroundColor: '#935CEB',
                height: 'auto',
                width: '100%',
                maxWidth: '600px',
                margin: '15px 0',
                padding: '25px',
                cursor: 'pointer',
                boxSizing: 'border-box',
                borderRadius: '8px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              }}
              key={index}
            >
              <Card.Body>
                <Card.Title style={{ color: '#ffffff', marginBottom: '15px', fontSize: '18px' }}>
                  <strong>Question:</strong>
                  <br />
                  {qa.Question.split('\n').map((line, idx) => (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  ))}
                </Card.Title>
                <Card.Text style={{ color: '#ffffff', fontSize: '16px' }}>
                  <strong>Answer:</strong>
                  <br />
                  {qa.Answer.split('\n').map((line, idx) => (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  ))}
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CppQAPortal;

import React from 'react';

const TermsOfService = () => {
  return (
    <div style={termsPageStyle}>
      <h1>Terms of Service</h1>
      <p>Effective Date: [Insert Date]</p>
      <p>By accessing or using the AH Wings website, you agree to abide by these Terms of Service. If you do not agree with these terms, please do not use our site.</p>

      <h3>1. User Responsibilities</h3>
      <p>You agree to use the website in accordance with applicable laws and regulations. You are responsible for any content you submit or post on the site.</p>

      <h3>2. Prohibited Activities</h3>
      <p>You agree not to engage in the following activities:
        <ul>
          <li>Posting harmful or illegal content</li>
          <li>Impersonating other individuals</li>
          <li>Disrupting the functionality of the site</li>
        </ul>
      </p>

      <h3>3. Intellectual Property</h3>
      <p>The content on this website, including text, images, logos, and trademarks, is the property of AH Wings and is protected by copyright laws. You may not use or reproduce any content without permission.</p>

      <h3>4. Limitation of Liability</h3>
      <p>AH Wings is not responsible for any damages arising from the use or inability to use the website. We do not guarantee the accuracy or completeness of the content on the site.</p>

      <h3>5. Modifications</h3>
      <p>We reserve the right to modify or discontinue the website at any time without prior notice.</p>

      <h3>6. Termination</h3>
      <p>We may suspend or terminate your access to the website if you violate these Terms of Service.</p>

      <h3>7. Governing Law</h3>
      <p>These Terms of Service are governed by the laws of [your country/state].</p>

      <h3>8. Contact Us</h3>
      <p>If you have any questions about these Terms of Service, please contact us at: [insert contact details].</p>
    </div>
  );
};

// Styles for Terms of Service page
const termsPageStyle = {
  padding: '20px',
  maxWidth: '800px',
  margin: '0 auto',
  marginTop: '70px'
};

export default TermsOfService;

import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen((prevOpen) => !prevOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav style={navbarStyle}>
      {/* Make the logo clickable */}
      <Link to="/" style={{ ...logoStyle, textDecoration: 'none', color: 'white' }}>
        <img src="logo.png" alt="Logo" style={logoImageStyle} />
      </Link>
      <div style={menuContainerStyle} ref={menuRef}>
        <button style={menuButtonStyle} onClick={toggleMenu}>
          ☰
        </button>
        <ul
          style={{
            ...dropdownStyle,
            display: menuOpen ? 'block' : 'none',
            top: '40px', // Adjust the top value to ensure it does not hide behind the navbar
          }}
        >
          <li><Link to="/" style={linkStyle} onClick={closeMenu}>Home</Link></li>
          <li><Link to="/job-portal" style={linkStyle} onClick={closeMenu}>Job-Portal</Link></li>
          <li><Link to="/c-interview" style={linkStyle} onClick={closeMenu}>C</Link></li>
          <li><Link to="/cpp-interview" style={linkStyle} onClick={closeMenu}>C++</Link></li>
          <li><Link to="/python-interview" style={linkStyle} onClick={closeMenu}>Python</Link></li>
          <li><Link to="/java-interview" style={linkStyle} onClick={closeMenu}>Java</Link></li>
          <li><Link to="/html-interview" style={linkStyle} onClick={closeMenu}>HTML</Link></li>
          <li><Link to="/css-interview" style={linkStyle} onClick={closeMenu}>CSS</Link></li>
          <li><Link to="/sql-interview" style={linkStyle} onClick={closeMenu}>SQL</Link></li>
          
        </ul>
      </div>
    </nav>
  );
};

// Styles
const navbarStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 20px',
  backgroundColor: '#58368F',
  color: 'white',
  position: 'fixed',  // Fixed position at the top
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 1000,  // Ensures the navbar is always on top
  height: '50px',  // Adjust height if needed
};

const logoStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
};

const menuContainerStyle = {
  position: 'relative',
};

const menuButtonStyle = {
  display: 'block',
  fontSize: '24px',
  background: 'none',
  border: 'none',
  color: 'white',
  cursor: 'pointer',
  marginRight: '40px',
};

const dropdownStyle = {
  position: 'absolute',
  right: '0',
  backgroundColor: '#58368F',
  listStyle: 'none',
  margin: 0,
  padding: '10px 0',
  borderRadius: '8px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  zIndex: 1000,
};

const linkStyle = {
  display: 'block',
  padding: '10px 20px',
  textDecoration: 'none',
  color: 'white',
  fontSize: '18px',
  transition: 'background-color 0.2s',
};

const logoImageStyle = {
  width: '60px',
  height: '50px', // Adjust height as needed
};

linkStyle[':hover'] = {
  backgroundColor: '#935CEB',
};

export default Navbar;

import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap'; // Import Card component
import Papa from 'papaparse'; // Import Papa Parse for robust CSV parsing
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../App.css';

const PythonQAPortal = () => {
  const [qaData, setQaData] = useState([]); // State to hold question-answer data

  useEffect(() => {
    const fetchData = () => {
      fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vQFPR-Ug4448ebL2q_hTJ2uQU_8F_bcBW81jqrLN929FtP8hyzVH_ldbIvicUTrq1_tG5j__AD8pQQO/pub?gid=1754015940&single=true&output=csv') // Replace with Python CSV URL
        .then(response => response.text())
        .then(csvText => {
          Papa.parse(csvText, {
            header: true, // Treat the first row as column headers
            skipEmptyLines: true, // Skip empty rows
            complete: (result) => {
              setQaData(result.data); // Set parsed data into state
            },
          });
        })
        .catch(err => console.error('Error fetching data:', err));
    };

    fetchData(); // Fetch data initially
    const interval = setInterval(fetchData, 60000); // Polling for live updates every minute

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return (
    <div style={{ backgroundColor: '', padding: '20px', minHeight: '100vh',marginTop: '70px'  }}>
      {/* SEO Meta Tags */}
      <meta
        name="description"
        content="Explore Python interview questions and answers. Perfect for job preparation, covering topics like Python fundamentals, advanced Python concepts, data structures, and more."
      />
      <meta
        name="keywords"
        content="Python interview questions, Python coding questions, Python job interview preparation, Python tutorials, Python for beginners, Python advanced topics, Python data structures, Python algorithms, Python programming, Python object-oriented programming, Python data analysis, Python machine learning, Python deep learning, Python automation, Python for data scientists, Python for web development, Python Django, Python Flask, Python testing, Python API development, Python for AI, Python interview questions for experienced, Python interview tips, Python code challenges, Python interview practice, Python syntax, Python libraries, Python programming skills, Python scripting, Python for software developers, Python data manipulation, Python SQL, Python interview preparation, Python for automation testing"
      />

      <meta name="author" content="Your Name or Company" />

      <div className="container mt-4">
        <a
          className="text-black font-weight-bold mb-3"
          style={{
            textShadow: '1px 1px 2px white, 0 0 25px white, 0 0 5px white',
            fontSize: '30px',
            fontWeight: 'bold',
            textAlign: 'center',
            display: 'block',
            width: '100%',
            margin: '0 auto',
          }}
        >
          PYTHON INTERVIEW QUESTIONS
        </a>

        <div className="qa-listings" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          {qaData.map((qa, index) => (
            <Card
              style={{
                backgroundColor: '#935CEB',
                height: 'auto',
                width: '100%',
                maxWidth: '600px',
                margin: '15px 0',
                padding: '25px',
                borderRadius: '8px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              }}
              key={index}
            >
              <Card.Body>
                <Card.Title style={{ color: '#ffffff', fontSize: '18px' }}>
                  <strong>Question:</strong>
                  <br />
                  {qa.Question.split('\n').map((line, idx) => (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  ))}
                </Card.Title>
                <Card.Text style={{ color: '#ffffff', fontSize: '16px' }}>
                  <strong>Answer:</strong>
                  <br />
                  {qa.Answer.split('\n').map((line, idx) => (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  ))}
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>
      </div>

    </div>
  );
};

export default PythonQAPortal;

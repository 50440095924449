import React from 'react';


const About = () => {
  return (
    <div style={aboutPageStyle}>
      <h1>About AH Wings</h1>
      <p>Welcome to AH Wings, your go-to platform for [insert your business or website purpose here]. Our goal is to provide high-quality content and services to our users.</p>
      <p>We are dedicated to maintaining a user-friendly experience, ensuring that every visit to our site is valuable and informative.</p>
      <p>If you have any questions or need further information, feel free to contact us.</p>
    </div>
  );
};

// Styles for About page
const aboutPageStyle = {
  padding: '20px',
  maxWidth: '800px',
  margin: '0 auto',
  textAlign: 'center',
  marginTop: '70px'
};

export default About;

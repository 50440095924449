import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Papa from 'papaparse';
import '../App.css';
import AdSenseAd from "../components/Adsense";

const QAPortal = () => {
  const [qaData, setQaData] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vQFPR-Ug4448ebL2q_hTJ2uQU_8F_bcBW81jqrLN929FtP8hyzVH_ldbIvicUTrq1_tG5j__AD8pQQO/pub?output=csv')
        .then(response => response.text())
        .then(text => {
          Papa.parse(text, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
              const formattedData = result.data.map(row => ({
                Question: row.Question,
                Answer: row.Answer ? row.Answer.replace(/\n/g, '<br />') : '',
              }));
              setQaData(formattedData);
            },
            error: (error) => {
              console.error('Error parsing CSV:', error);
            }
          });
        })
        .catch(err => console.error('Error fetching data:', err));
    };

    fetchData();
    const interval = setInterval(fetchData, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ backgroundColor: '', padding: '20px', minHeight: '100vh',marginTop: '70px' }}>
      <Helmet>
        {/* SEO Optimization */}
        <title>C Interview Questions with Answers</title>
        <meta
          name="description"
          content="Prepare for your next technical interview with the best C programming interview questions and answers for freshers and experienced professionals."
        />
       <meta
          name="keywords"
          content="C interview questions, C programming interview, technical interview C, C language interview questions, C coding questions, C job preparation, freshers C interview, experienced C interview, C programming tips, C basic questions, C advanced questions, C memory management, C dynamic memory allocation, Pointers in C, Arrays in C, Strings in C, C data structures, Linked lists in C, Recursion in C, Static vs global variables in C, C preprocessor directives, Typecasting in C, Null pointers in C, Call by value vs call by reference, C algorithm questions, C coding interview problems, C programming test questions, C debugging examples, C storage classes, ANSI C standards, Volatile keyword in C, Nested structures in C, Enum and union in C, Real-world C projects, C embedded systems programming, C programming for system design"
        />

        <meta
          name="robots"
          content="index, follow"
        />
      </Helmet>
      <div className="container mt-4">
        <a
          className="text-black font-weight-bold mb-3"
          style={{
            textShadow: '1px 1px 2px white, 0 0 25px white, 0 0 5px white, 0 0 5px white',
            fontSize: '30px',
            fontWeight: 'bold',
            textAlign: 'center',
            display: 'block',
            width: '100%',
            margin: '0 auto',
          }}
        >
          C INTERVIEW QUESTIONS
        </a>
       
        <div className="qa-listings" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        
          {qaData.map((qa, index) => (
            <Card
              style={{
                backgroundColor: '#935CEB',
                height: 'auto',
                width: '100%',
                maxWidth: '600px',
                margin: '10px 0',
                padding: '25px',
                cursor: 'pointer',
                boxSizing: 'border-box',
                borderRadius: '8px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                overflow: 'hidden',
                maxHeight: '400px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
              key={index}
            >
              <Card.Body>
                <Card.Title style={{ color: '#ffffff', marginBottom: '15px', fontSize: '18px' }}>
                  <strong>Question:</strong> {qa.Question}
                </Card.Title>
                <Card.Text
                  style={{ color: '#ffffff', fontSize: '16px' }}
                  dangerouslySetInnerHTML={{ __html: `<strong>Answer:</strong> ${qa.Answer}` }}
                />
              </Card.Body>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QAPortal;

import React from 'react';

const Footer = () => {
  return (
    <footer style={footerStyle}>
      <p>&copy; 2024 AH Wings</p>
      <ul style={footerLinksStyle}>
        <li><a href="/about" style={footerLinkStyle}>About</a></li> {/* Added About Link */}
        <li><a href="/privacy" style={footerLinkStyle}>Privacy Policy</a></li>
        <li><a href="/terms" style={footerLinkStyle}>Terms of Service</a></li>
      </ul>
    </footer>
  );
};

// Styles
const footerStyle = {
  backgroundColor: '#58368F',
  color: 'white',
  textAlign: 'center',
  padding: '20px 0',
  marginTop: 'auto',
};

const footerLinksStyle = {
  display: 'flex',
  justifyContent: 'center',
  gap: '15px',
  listStyle: 'none',
  padding: 0,
  margin: 0,
  flexWrap: 'wrap',
};

const footerLinkStyle = {
  color: 'white', // Ensures link text is white
  textDecoration: 'none', // Removes underline
  fontSize: '16px', // Optional: Adjust font size
  transition: 'color 0.2s', // Smooth hover effect
};

// Optional hover effect for links
footerLinkStyle[':hover'] = {
  color: '#bbbbff', // Light blue hover color
};

export default Footer;
